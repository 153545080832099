import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { EditionDataGuard } from './guards';
import { ContactsGuard } from './contacts/guards';
import { CheckAuthGuard } from './auth/guards/checkAuth.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { StatisticOverviewGuard } from './statistics/guards';
import { SocialChannelsGuard } from './social-media/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, ContactsGuard, EditionDataGuard, CheckAuthGuard, SocialChannelsGuard];
const signedInGuards = [AuthGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [...appGuards],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
  },
  {
    path: 'opdrachten',
    loadChildren: () => import('./assignments/assignments.module').then(m => m.AssignmentsModule),
    canActivate: [...appGuards, ...signedInGuards],
  },
  {
    path: 'statistieken',
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [...appGuards, ...signedInGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '500',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: 'veelgestelde-vragen',
    pathMatch: 'prefix',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    canActivate: [...appGuards],
  },
  {
    path: 'opdrachten',
    loadChildren: () => import('./assignments/assignments.module').then(m => m.AssignmentsModule),
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
